import pearsonTheme from '@greenville/mui-theme';

const theme = {
  activeColor: 'default',
  progress: {
    color: '#047a9c',
    zIndex: 1000,
    margin: '150px 48%',
    position: 'absolute'
  },
  icons: {
    foreColor: '#252525',
    backColor: '#fff'
  },
  slider: {
    selectionColor: '#22b6b4',
    thumbSize: '15px',
    trackSize: '2px',
    trackColor: '#d9d9d9'
  },
  mixins: pearsonTheme.mixins,
  palette: pearsonTheme.palette,
  props: pearsonTheme.props,
  spacing: pearsonTheme.spacing,
  typography: pearsonTheme.typography,
  // not overriding all the styles of @greenville/mui-theme
  // as it breaks the zeplin designs
  // overrides: pearsonTheme.overrides,
  overrides: {
    MuiAppBar: pearsonTheme.overrides.MuiAppBar,
    MuiAvatar: pearsonTheme.overrides.MuiAvatar,
    MuiButton: pearsonTheme.overrides.MuiButton,
    MuiButtonBase: pearsonTheme.overrides.MuiButtonBase,
    MuiDivider: pearsonTheme.overrides.MuiDivider,
    MuiIconButton: pearsonTheme.overrides.MuiIconButton,
    MuiListItem: pearsonTheme.overrides.MuiListItem,
    MuiMenuItem: pearsonTheme.overrides.MuiMenuItem,
    MuiPaper: pearsonTheme.overrides.MuiPaper,
    MuiPopover: pearsonTheme.overrides.MuiPopover,
    MuiRadio: pearsonTheme.overrides.MuiRadio,
    MuiSlider: pearsonTheme.overrides.MuiSlider,
    MuiSvgIcon: pearsonTheme.overrides.MuiSvgIcon,
    MuiTab: pearsonTheme.overrides.MuiTab,
    MuiToolbar: pearsonTheme.overrides.MuiToolbar,
    MuiTypography: pearsonTheme.overrides.MuiTypography,
    MuiVerticalTabs: pearsonTheme.overrides.MuiVerticalTabs
  }
};

export default theme;