/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * ComponentOwner is the wrapper container
 *
 * @file ComponentOwner.jsx
 * @author Sam George
 * @since 2.0.0
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { EventProvider } from '@aquila/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import theme from '../utils/muiTheme';

class ComponentOwner extends PureComponent {
  render() {
    const {
      children
    } = this.props;

    return (
      <EventProvider>
        <ThemeProvider theme={createMuiTheme(theme)}>
          <Router>
            {children}
          </Router>
        </ThemeProvider>
      </EventProvider>
    );
  }
}

ComponentOwner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

ComponentOwner.defaultProps = {
  children: null
};

export default ComponentOwner;
