/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2019 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * This will load the Vegareader or details forms
 * based on the user entered details
 *
 * @file App.js
 * @author Srinivasan Bakthavatchalam
 * @since 2.0.0
 */

import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import pearsonTheme from '@greenville/mui-theme';
import theme from './utils/muiTheme';

import DetailsForm from './demo/DetailsForm';
import VegaReaderContainer from './demo/VegaReaderContainer';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookId: '',
      contentType: ''
    };
  }

  setDetails = (values) => {
    try {
      const tempValues = values;
      this.setState(tempValues);
    } catch (e) {
      this.setState({
        bookId: '',
        contentType: ''
      });
    }
    if (values.token) {
      window.piSession = {};
      window.piSession.currentToken = () => values.token;
    }
  }

  render() {
    const selectedTheme = createMuiTheme(theme);
    if (this.state.contentType !== '' && this.state.bookId !== '') {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (
        <MuiThemeProvider theme={selectedTheme}>
          <VegaReaderContainer {...this.state} />
        </MuiThemeProvider>
      ); // TODO : need to fix this, rewrite this line
    }
    return <DetailsForm setDetails={this.setDetails} />;
  }
}
export default App;
